<template>
    <div class="home">
        <el-container>
            <el-header style="height:120px">
                <div class="headerbox">
                    <img class="headerimg" src="../assets/logo.png">
                    <div class="headertext">免费咨询热线：0755-28711778</div>
                </div>
                <div class="navtypebox">
                    <div ref="nav" class="navtypeone" @click="changenav('nav','/index')">关于我们</div>
                    <el-popover placement="bottom" v-model="visible" trigger="click">
                        <div class="navsmall" v-for="(item,index) in navlist" :key="index" @click="navsmall(item.path)">{{item.name}}</div>
                        <div slot="reference" ref="nav1" class="navtypeone" @click="changenav('nav1','')">业务与产品</div>
                    </el-popover>
                    <!-- <div ref="nav2" class="navtypeone" @click="changenav('nav2','/case')">典型案例</div> -->
                    <div ref="nav3" class="navtypeone" @click="changenav('nav3','/dynamic')">集团动态</div>
                </div>
            </el-header>
            <el-main>
                <router-view></router-view>
            </el-main>
            <el-footer>
                <div class="footer">
                    <div class="footerleft">
                        <div>关于标榜</div>
                        <div>标榜优势</div>
                        <div>标榜简介</div>
                        <div>客服电话</div>
                    </div>
                    <div class="footercenter">
                        <div class="centerlink">联系我们</div>
                        <div class="centerlink">
                            <div>教育咨询热线:</div>
                            <div>0755-28711778</div>
                        </div>
                        <div class="centerlink">
                            <div>营业执照的地址：</div>
                            <div>深圳市福田区沙头街道天安社区深南大道6013号中国有色大厦1501</div>
                        </div>
                        <div class="centerlink">
                            <div>龙岗分部</div>
                            <div>公司地址:深圳市龙岗区南湾街道科技园路12号荣丰大厦8楼</div>
                        </div>
                        
                    </div>
                    <div class="footerright">
                        <div class="codeimg">
                            <img src="../assets/index/code.png" alt="">
                        </div>
                        <div class="wxbox">
                            <div class="wximg">
                                <img src="../assets/index/wx.png" alt="">
                            </div>
                            <div >集团公众号</div>
                        </div>
                        <div class="codetext">扫码关注</div>

                    </div>
                </div>
                <div class="footbt" @click="gobeian">
                     备案号:粤ICP备2024204400号-1
                </div>
            </el-footer>
        </el-container>
    </div>
</template>

<script>
export default {
    name: "Home",

    data() {
        return {
            visible: false,
            navlist: [
                {
                    name: "业务体系",
                    path: "/job",
                },
                {
                    name: "托幼一体",
                    path: "/integration",
                },
                {
                    name: "产教融合",
                    path: "/fuse",
                },
                {
                    name: "一问培训",
                    path: "/anissue",
                },
                {
                    name: "科技赋能",
                    path: "/science",
                },
            ],
        };
    },
    created(){
       
       window.addEventListener('scroll', this.handleScroll, true)


    },
    mounted() {
            this.$refs[sessionStorage.getItem('index') ].style.color = "#BE413F";
    },
    methods: {
        gobeian(){
            window.open('https://beian.miit.gov.cn')
        },
         handleScroll () {
            let scrollTop = document.documentElement.scrollTop;
            let ch = document.documentElement.clientHeight;
            let sh = document.documentElement.scrollHeight;
            if(scrollTop + ch >= sh - 800){
              this.$bus.$emit('changeemailshow',true)
            }else{
              this.$bus.$emit('changeemailshow',false)

            }
        },
        navsmall(path) {
            this.$router.push(path)
            this.visible = false;
        },
        changenav(index,path) {
            this.$refs.nav.style.color = "";
            this.$refs.nav1.style.color = "";
            // this.$refs.nav2.style.color = "";
            this.$refs.nav3.style.color = "";
            this.$refs[index].style.color = "#BE413F";
            sessionStorage.setItem('index',index) 
            if(path){
            this.$router.push(path)
            }

        },
    },
};
</script>
<style>
.el-popover {
    min-width: 100px !important;
}
</style>
<style lang="scss" scoped>
.navsmall {
    text-align: center;
    font-size: 14px;
    padding: 15px 0;
    cursor: pointer;
}

.navsmall:active {
    color: #be413f;
}
.navsmall:hover {
    color: #be413f;
}
.home {
    /deep/.el-header{
        padding: 0;
    }
    /deep/.el-main{
        padding: 0;
    }
    /deep/.el-footer {
        padding: 0;
    }
    .headerbox {
        display: flex;
        justify-content: space-around;
        align-items: center;
        .headerimg {
            height: 55px;
        }
        .headertext {
            font-size: 20px;
            font-weight: bold;
            color: #000000;
        }
    }
    .navtypebox {
        display: flex;
        justify-content: space-around;
        margin: 20px 0;
        .navtypeone {
            font-size: 16px;
        }
    }
    .footer {
        padding: 60px 0;
        background: #2d3237;
        display: flex;
        height: 210px;
        justify-content: center;
        font-size: 16px;
        color: #ffffff;
        .footerleft {
            border-right: 1px solid #ffffff;
            padding-right: 20px;
            div {
                margin-bottom: 20px;
            }
        }
        .footercenter {
            padding-left: 80px;
            width: 50%;
            .centerlink {
                margin-bottom: 20px;
            }
        }
        .footerright {
            width: 110px;
            .codeimg {
                img {
                    width: 100%;
                }
            }
            .wxbox {
                display: flex;
                    align-items: center;

                .wximg {
                    width: 30px;
                    display: flex;
                    img {
                        width: 100%;
                    }
                }
            }
            .codetext{
                color:#999;
                text-align: center;
            }
        }
    }
    .footbt{
        width: 100%;
        background: #101010;
        height: 30px;
        color: #fff;
        text-align: center;
        font-size: 16px;
        line-height: 30px;
    }
}
</style>
